
import { get, post } from '../request'
/**优惠券管理模块 */
export default {
    /**
        * @description 查询所有优惠券列表
        * @param {String|Number} params 
        * @returns Object
        */
    getAllCoupon(params) {
        return get("/coupon/select/" + params, {}, true)
    },
    /**
    * @description 修改或添加优惠券
    * @param {Object} params 
    * @returns Object
    */
    postUpdateOrAddCoupon(params) {
        return post("/updateOrAdd", params)
    },

    /**
     * @description 按ID查询洗车卷
     * @param {String} params 
     * @returns Object
     */
    getCarCouponSelectById(params) {
        return get(`/carCoupon/selectById/${params}`)
    },
    /**
     * @description 修改洗车券
     * @param {Object} params 
     * @returns Object
     */
    postUpdateCarCoupon(params) {
        return post("/carCoupon/update", params)
    },
    /**
    * @description 查找洗车券
    * @param {Object} params 
    * @returns Object
    */
    getSelectCarCoupon(params) {
        return get("/carCoupon/select", params, true)
    },
    /**
        * @description 优惠券添加
        * @param {Object} params 
        * @returns Object
        */
    postAddCoupon(params) {
        return post("/coupon/newCouponUpdateOrAdd", params)
    },
    /**
     * @description 查找所有优惠券
     * @param {Object} params 
     * @returns Object
     */
    getSelectAllCoupon(params) {
        return get("/coupon/selectAll", params, true)
    },
    /**
    * @description 优惠券删除
    * @param {String} params 
    * @returns Object
    */
    getCouponDelById(params) {
        return get(`/coupon/delete/${params}`)
    },

    /**
      * @description 通过活动No查询活动信息
      * @param {String} params 
      * @returns Object
      */
    getActivityDetailsById(params) {
        return get(`/coupon/activity/activityDetails?activityNo=${params}`)
    },
    /**
     * @description 新增活动
     * @param {Object} params 
     * @returns Object
     */
    postAddActivity(params) {
        return post("/coupon/activity/creat", params)
    },
    /**
     * @description 活动修改
     * @param {Object} params 
     * @returns Object
     */
    postUpdateActivity(params) {
        return post("/coupon/activity/activityDetails", params)
    },

    /**
       * @description 活动详情查询
       * @param {String} params 
       * @returns Object
       */
    getActivityDetailsByNo(params) {
        return get(`/coupon/activity/details?activityNo=${params}`)
    },



    /**
     * @description 活动列表查询
     * @param {String} params 
     * @returns Object
     */
    getActivityList(params) {
        return get(`/coupon/activity/selectAll`, params, true)
    },
    /**
     * @description 活动删除
     * @param {String} params 
     * @returns Object
     */
    getActivityDelByNo(params) {
        return get(`/coupon/activity/del?activityNo=${params}`)
    },
    /**
     * @description 查询全分组信息
     * @param {String} params 
     * @returns Object
     */
    getCouponGroup(params) {
        return get(`/coupon/group/selectAll`, params, true)
    },



    /**
     * @description 优惠券查询
     * @param {String} params 
     * @returns Object
     */
    getIsUseCouponSelect(params) {
        return get(`/coupon/issue/select/${params}`)
    },
    /**
     * @description 优惠券添加 /修改
     * @param {String} params 
     * @returns Object
     */
    postIsUseCouponAdd(params) {
        return post(`/coupon/issue/updateOrAdd`, params)
    },
    /**
     * @description 优惠券查询所有不分页
     * @returns Object
     */
    getAllCouponNoPage() {
        return get(`/coupon/selectAllNotPage`,{},true)
    },
    /**
     * @description 优惠券查询所有分组不分页
     * @returns Object
     */
    getAllCouponGroupNoPage() {
        return get(`/coupon/group/selectAllNotPage`)
    },
    /**
     * @description 优惠券发放查询所有
     * @param {String} params 
     * @returns Object
     */
    getIsuseAllCoupon(params) {
        return get(`/coupon/issue/selectAll`, params)
    },


    /**
         * @description 优惠券使用查询所有
         * @param {String} params 
         * @returns Object
         */
    getUsedAllCoupon(params) {
        return get(`/coupon/user/selectAll`, params, true)
    },

    /**
     * @description 优惠券删除
     * @param {String} params 
     * @returns Object
     */
    getIsUseCouponDel(params) {
        return get(`/coupon/issue/delete/${params}`)
    },



      /**
     * @description 优惠支付方式 有全部
     * @param {String} params 
     * @returns Object
     */
      getCouponPayWayList() {
        return get(`/payWay/selectPayWayList`,{},true)
    },
    /**
     * @description 支付类型 有全部
     * @param {String} params 
     * @returns Object
     */
    getPayTypeList() {
        return get(`/payWay/selectPayTypeList`,{},true)
    },
    /**
      * @description 优惠支付方式 没有全部
      * @param {String} params 
      * @returns Object
      */
    getCouponPayWay() {
        return get(`/payWay/selectPayWay`)
    },
    /**
     * @description 优惠券统计
     * @param {String} params 
     * @returns Object
     */
    getCouponStatistics(param) {
        return get(`/coupon/user/statistics`, param,true)
    },

    /**
    * @description 按手机号补发优惠券
    * @param {String} params 
    * @returns Object
    */
    reissueCouponByPhone(param) {
        return get(`/coupon/receiveCouponVTwo`, param)
    },
      /**
    * @description 按手机号补发优惠券
    * @param {String} params 
    * @returns Object
    */
      reissueCouponByPhone(param) {
        return get(`/coupon/receiveCouponVTwo`, param)
    },

}